import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import DepartmentCards from '../components/DepartmentCards';
import ModularBlocks from '../components/ModularBlocks';

const DepartmentsPage = () => {
  const {
    datoCmsDepartmentsArchive: {
      seoMetaTags,
      title,
      bannerText,
      modularBlocks,
    },
  } = useStaticQuery(graphql`
    query DepartmentsPageQuery {
      datoCmsDepartmentsArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
        modularBlocks {
          ...ContentModularBlockFragment
          ...ImageCarouselModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout altTheme={true}>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          overline="Departments"
          heading={title}
          text={bannerText}
          altTheme={true}
        />
        <DepartmentCards />
        <ModularBlocks items={modularBlocks} altTheme={true} />
      </main>
    </Layout>
  );
};

export default DepartmentsPage;
