import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CtaCards from '../components/CtaCards';
import CtaCard from '../components/CtaCard';

const DepartmentCards = () => {
  const {
    allDatoCmsDepartment: { departments },
  } = useStaticQuery(graphql`
    query DepartmentCardsQuery {
      allDatoCmsDepartment(sort: { fields: position }) {
        departments: nodes {
          id
          title
          slug
          description
        }
      }
    }
  `);

  return (
    departments.length > 0 && (
      <CtaCards>
        {departments.map(({ id, title, slug, description }) => (
          <CtaCard
            key={id}
            heading={title}
            text={description}
            link={{ slug: `departments/${slug}`, text: 'View department' }}
          />
        ))}
      </CtaCards>
    )
  );
};

export default DepartmentCards;
